import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
	//   const router = useRouter()
	const runtimeConfig = useRuntimeConfig();

	if (!runtimeConfig.public.sentry.dsn) {
		return;
	}

	if (
		runtimeConfig.public.sentry.environment !== "dev" &&
		runtimeConfig.public.sentry.environment !== "test"
	) {
		Sentry.init({
			app: nuxtApp.vueApp,
			dsn: runtimeConfig.public.sentry.dsn,
			environment: runtimeConfig.public.sentry.environment,
			ignoreErrors: ["Page Not Found"]
			// integrations: [
			//   new Sentry.BrowserTracing({
			//     routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			//   }),
			//   new Sentry.Replay(),
			// ],

			// tracesSampleRate: 0.2, // Change in prod

			// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
			// tracePropagationTargets: ['localhost', runtimeConfig.public.hostname],

			// replaysSessionSampleRate: 1.0, // Change in prod
			// replaysOnErrorSampleRate: 1.0, // Change in prod if necessary
		});
	}
});
